<template>
    <div class="hardAnalyList">
        <div class="search_container searchArea">
            <el-form :inline="true" :model='searchForm' ref="searchForm" class="demo-form-inline search-form">
                <el-form-item label="单位">
                    <el-select v-model="searchForm.schoolId" filterable placeholder="请选择">
                        <el-option key="" label="全部" value=""></el-option>
                        <el-option v-for="item in schoolList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="专业">
                    <el-select v-model="searchForm.professionId" filterable placeholder="请选择">
                        <el-option key="" label="全部" value=""></el-option>
                        <el-option v-for="item in professionList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="查找">
                    <el-input v-model="searchForm.searchText" placeholder="名称或入学年"  @keyup.enter.native='loadhardAnalyList'></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="info" size ="mini" icon="search" @click='loadhardAnalyList'>查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size ="mini" icon="search" :disabled="anaData.length<1" @click='toExcel'>导出</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-row>
          <el-col :span="12">
            <div class="my-table" >
                <el-table v-loading="loading" :data="tableData" style="width: 100%;" :max-height="tableHeight" align='center'>
                    <el-table-column prop="name" label="名称"  min-width="250" show-tooltip-when-overflow align='center' ></el-table-column>
                    <el-table-column prop="year" label="入学年" align='center' ></el-table-column>
                    <el-table-column prop="student_count" label="学生数量" align='center' ></el-table-column>
                    <el-table-column prop="operation" align='center' label="操作" width="300">
                        <template slot-scope='scope'>
                            <el-button icon='edit' size="mini" @click='toLeft(scope.row)'>添加</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <pagination  :pageSize="searchForm.pageSize" :currentPage="searchForm.pageNo" :pageTotal="searchForm.totalCount" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"></pagination>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="my-table">
              <el-table v-loading="loading" :data="anaData" style="width: 100%" :max-height="tableHeight" align='center'>
                <el-table-column prop="name" label="名称"  min-width="250" show-tooltip-when-overflow  align='center' ></el-table-column>
                <el-table-column prop="year" label="入学年" align='center' ></el-table-column>
                <el-table-column prop="student_count" label="学生数量" align='center' ></el-table-column>
                <el-table-column prop="operation" align='center' label="操作" width="300">
                  <template slot-scope='scope'>
                    <el-button type="danger" icon='edit' size="mini" @click='onDeleteHardClass(scope.row, scope.$index)'>删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
        </el-row>

    </div>
</template>

<script>
    import * as mUtils from '@/utils/mUtils'
    import {mapState, mapActions} from 'vuex'
    import XEUtils from 'xe-utils'
    import * as simpleApi from "request/simple";
    import Pagination from "@/components/pagination";
    import XLSX from "xlsx";

    export default {
        name: 'hardAnalyList',
        data(){
            return {
                tableData: [], tableHeight:0, anaData: [],
                loading:true, isShow:false,
                schoolList: [], professionList: [],
                selHardClass: null, selHardClassDtl: null,
                searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null, schoolId: null, professionId: null},
            }
        },
        components:{
            Pagination
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            })
        },
      created() {
        let myHeight = document.documentElement.clientHeight ;
        this.tableHeight = myHeight - 250;
      },
      mounted() {

            this.loadSchoolList();
            this.loadProfessionList();
            this.loadhardAnalyList();
	   },
        methods: {

            loadhardAnalyList(){
                this.loading = true;

                let search = { searchText: mUtils.searchText(this.searchForm.searchText), licenceId: this.userInfo.licenceId,
                    schoolId: mUtils.searchText(this.searchForm.schoolId), professionId: mUtils.searchText(this.searchForm.professionId)};
                let param = { controllerName: 'sclHardClass', methodName: '/list', pageNo: this.searchForm.pageNo, pageSize: this.searchForm.pageSize, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.tableData = data.result;
                        this.searchForm.pageNo = data.pageNo;
                        this.searchForm.pageSize = data.pageSize;
                        this.searchForm.totalCount = data.totalCount;
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                    console.log("error")
                });
            },
            loadSchoolList(){

                let search = { licenceId: this.userInfo.licenceId};
                let param = { controllerName: 'school', methodName: '/list', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.schoolList = data.result;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            loadProfessionList(){

                let search = { licenceId: this.userInfo.licenceId};
                let param = { controllerName: 'sclProfession', methodName: '/list', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.professionList = data.result;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },

            // 上下分页
            handleCurrentChange(val){
                this.searchForm.pageNo = val;
                this.loadhardAnalyList()
            },
            // 每页显示多少条
            handleSizeChange(val){
                this.searchForm.pageSize = val;
                this.loadhardAnalyList()
            },
            onDeleteHardClass(row, idx){
                console.log("delete", idx)
                this.anaData.splice(idx, 1)
            },
            toLeft(row){
              let ar = XEUtils.find(this.anaData, item =>  item.id == row.id );
              if(!ar) this.anaData.push(row)
            },
          toExcel(){
            let hardClassIds = []
            this.anaData.forEach((item) =>{
              hardClassIds.push(item.id)
            })
            let search = { licenceId: this.userInfo.licenceId, hardClassIds: hardClassIds};
            let param = { controllerName: 'sclHardClass', methodName: '/hardClassList', param: search};
            return simpleApi.post(param).then(({result: {code, data}}) =>  {
              console.log("hardClassList", data)
              if (data && data.length > 0) {
                let sht = {}
                for (let i = 0; i < data.length; i++) {
                  let d = data[i]
                  let hardName = d.hard_name.replace("\\", "").replace("/", "_")
                  if(hardName in sht){
                    let maxStudents = "", minStudents = "";
                    if(d.max_students && d.max_students.length > 0) maxStudents = d.max_students.join(",")
                    if(d.min_students && d.min_students.length > 0) minStudents = d.min_students.join(",")
                    sht[hardName].push([d.exam_id, d.exam_name, d.full_score, d.hard_avg, d.hard_total, d.hard_jige, d.pm, d.level_A, d.level_B, d.level_C, d.level_D, d.level_E, d.max_score, maxStudents, d.min_score, minStudents ])
                  }else{
                    sht[hardName] = [['考试ID', '考试名称', '考试总分', '平均分', '考试人数', '及格人数', '班级排名', 'A级人数', 'B级人数', 'C级人数', 'D级人数', 'E级人数', '最高分', '最高分学生', '最低分', '最低分学生']]
                  }
                }
                let sheetNames = [];
                let sheetsList = {};
                let wb = XLSX.utils.book_new();
                for(let s in sht){
                  sheetNames.push(s)
                  sheetsList[s] = XLSX.utils.aoa_to_sheet(sht[s]);
                }
                console.log("sheetNames", sheetNames)
                wb["SheetNames"] = sheetNames;
                wb["Sheets"] = sheetsList;

                XLSX.writeFile(wb, "班级统计数据.xlsx");
              }
            }).catch((error) => {
              console.log("error")
            });
          }

        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .search_container{

    }
    .btnRight{
        float: right;
        margin-right: 0px !important;
    }
    .searchArea{
        background:rgba(255,255,255,1);
        border-radius:2px;
        padding: 18px 18px 0;
    }
    .my-table{
        padding: 10px;
        background: #fff;
        border-radius: 2px;
    }
    .el-dialog--small{
       width: 600px !important;
    }
    .pagination{
        text-align: left;
        margin-top: 10px;
    }

</style>


